<div class="app-layout" [class.widescreen]="config.viewWidescreen">
  <div class="activitybar">
    <div routerLink="/">
      <svg class="saq-logo" viewBox="0 0 192.756 192.756">
        <path fill="transparent" d="M0 0h192.756v192.756H0V0z"/>
        <path d="M103.498 146.264V117.76h28.523V46.493H60.734v71.267h28.524v28.504H32.232V17.97h128.291v128.294h-57.025zM61.911 160.525h68.935v14.262H61.911v-14.262z"/>
      </svg>
    </div>
    <div>
      <saq-omnibox></saq-omnibox>
    </div>
    <div>
      <button class="toggle-filters" (click)="toggleFilters()" [class.active]="config.viewFilters" title="Filtres">
        <svg viewBox="0 0 24 24">
          <path d="M3,17V19H9V17H3M3,5V7H13V5H3M13,21V19H21V17H13V15H11V21H13M7,9V11H3V13H7V15H9V9H7M21,13V11H11V13H21M15,9H17V7H21V5H17V3H15V9Z" />
        </svg>
      </button>
      <button class="toggle-widescreen" (click)="toggleWidescreen()" [class.active]="config.viewWidescreen" title="Grand &eacute;cran">
        <svg viewBox="0 0 24 24">
          <path d="M12,4C9.27,4 6.78,4.24 4.05,4.72L3.12,4.88L2.87,5.78C2.29,7.85 2,9.93 2,12C2,14.07 2.29,16.15 2.87,18.22L3.12,19.11L4.05,19.27C6.78,19.76 9.27,20 12,20C14.73,20 17.22,19.76 19.95,19.28L20.88,19.12L21.13,18.23C21.71,16.15 22,14.07 22,12C22,9.93 21.71,7.85 21.13,5.78L20.88,4.89L19.95,4.73C17.22,4.24 14.73,4 12,4M12,6C14.45,6 16.71,6.2 19.29,6.64C19.76,8.42 20,10.22 20,12C20,13.78 19.76,15.58 19.29,17.36C16.71,17.8 14.45,18 12,18C9.55,18 7.29,17.8 4.71,17.36C4.24,15.58 4,13.78 4,12C4,10.22 4.24,8.42 4.71,6.64C7.29,6.2 9.55,6 12,6Z" />
        </svg>
      </button>
    </div>
  </div>
  <div class="main">
    <router-outlet></router-outlet>
  </div>
  <footer>
    <div class="content">
      <div>
        <div style="padding-bottom: 0.5rem">Commandez tous vos produits pr&eacute;f&eacute;r&eacute;s sur le <a href="https://www.saq.com/fr/produits">vrai site de la SAQ</a> ou <a href="https://www.saq.com/fr/store">visitez un magasin</a> pr&egrave;s de chez vous.</div>
        &copy; 2021 Angry Ninja. Tous droits r&eacute;serv&eacute;s.
      </div>
      <div>
        <img src="assets/svg/moderation.svg" alt="La modération a bien meilleur goût.">
      </div>
    </div>
  </footer>
</div>
